<template>
    <div>
        <section v-if="!this.login">
            <table class="table" style="max-width: 90%;">
                <thead>
                    <tr>
                        <th scope="col">N° protocolo</th>
                        <th scope="col">CPF</th>
                        <th scope="col">Nome completo</th>
                        <th scope="col">Telefone</th>
                        <th scope="col">Nome da mãe</th>
                        <th scope="col">Data nascimento</th>
                        <th scope="col">Data retirada</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="(user, indice) in this.users">
                        <td>{{ user.ticketnumber ?? 'Não encontrado' }} </td>
                        <td><a :href="`/galeria/${user.cpf}`">{{ formattCPF(user.cpf) }} </a></td>
                        <td>{{ user.fullname ?? 'Não encontrado' }} </td>
                        <td>{{ user.mobilephone ?? 'Não encontrado' }} </td>
                        <td>{{ user.nome_mae ?? 'Não encontrado' }} </td>
                        <td>{{ formatDate(user.birthdate) ?? 'Não encontrado' }} </td>
                        <td>{{ formatDate(user.createdon) ?? 'Não encontrado' }} </td>
                    </tr>
                </tbody>
            </table>
            <nav aria-label="Navegação de página exemplo">
                <ul class="pagination">
                    <li class="page-item" @click="this.previousPage()"><a class="page-link">Anterior</a></li>
                    <li class="page-item" @click="this.nextPage()"><a class="page-link">Próximo</a></li>
                </ul>
            </nav>
            {{ this.pageAtual }}
        </section>

        <div v-else
            style="display: flex; justify-content: center; align-items: center; margin: auto; margin-top: 100px;">
            <img src="../assets/img/icons/circle-1700_512.gif" alt="" srcset="">
        </div>
    </div>
</template>

<script>
import axios from 'axios';

export default {
    name: "Tables",
    data() {
        return {
            users: [],
            login: false,
            page: 1,
        }
    },

    async created() {
        this.listUsers(this.page)
    },

    watch: {
        page(newValue, oldValue) {
            this.listUsers(newValue)
        }
    },

    methods: {
        async listUsers(value) {
            this.login = true
            const request = await axios({
                method: "GET",
                url: `${process.env.VUE_APP_BACKEND_UPLOAD}/api/users?page=${value}`,
                headers: {
                    "x-api-key": "84093d39-28b4-414f-bd1c-979a10407c8f",
                    "Access-Control-Allow-Origin": "*",
                }
            });
            const response = await request.data.data
            this.users = response.itens
            this.pageAtual = response.pagina
            this.login = false
        },
        formattCPF(cpf) {
            // console.log('aqui', cpf);
            return cpf.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, '$1.$2.$3-$4');
        },
        formatDate(dataString) {
            if (dataString !== undefined) {
                const dataFormatada = new Date(dataString).toLocaleString('pt-BR')
                return dataFormatada.split(',')[0];
            }
            return undefined
        },
        async previousPage() {
            if (this.page !== 0) {
                this.page--
            }
        },
        async nextPage() {
            this.page++
        }

    }
}

</script>

<style scoped>
ul li {
    cursor: pointer;
}
</style>