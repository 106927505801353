<template>
    <div class="container-fluid py-4 px-5">
        <h3>Galeria de Imagem
            <img class="icon-badeira" src="../assets/img/icons/icon-alert.png" alt="logo-galeria" srcset="">
        </h3>
        <section class="mt-5">
            <h5>Usuário: <strong>{{ this.cpf.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, '$1.$2.$3-$4') }}</strong></h5>
            <div class="container mt-5">
                <div class="row">
                    <div class="col-lg-3 col-md-6 col-sm-12 my-5" v-for="(image, index) in this.images ">
                        <img class="img-card" :src="image.url" alt="" :key="index" data-toggle="modal"
                            data-target=".bd-example-modal-lg" @click="openModal(image.url)">
                    </div>
                </div>
            </div>
            <!-- modal -->
            <div v-if="this.modal" class="modal">
                <div class="modal-body">
                    <span class="close" @click="closeModal()">x</span>
                    <img class="img-card-modal" :src="this.srcImageSelected" alt="image-modal" srcset="">
                </div>
            </div>
        </section>
    </div>
</template>

<script>
import axios from 'axios';

export default {
    name: 'Galeria',
    data() {
        return {
            cpf: '',
            images: [],
            srcImageSelected: '',
            modal: false
        }
    },
    async created() {
        await this.listPhotos()
    },
    methods: {
        async listPhotos() {
            const request = await axios({
                method: "GET",
                url: `${process.env.VUE_APP_BACKEND_UPLOAD}/api/arquivos/${this.$route.params.id}`,
                headers: {
                    "x-api-key": "84093d39-28b4-414f-bd1c-979a10407c8f",
                    "Access-Control-Allow-Origin": "*"
                }
            })
            const response = await request.data
            this.cpf = response.cpf
            this.images = response.images
        },
        openModal(value) {
            this.srcImageSelected = value
            this.modal = true
        },
        closeModal() {
            this.srcImageSelected = '',
                this.modal = false
        }
    },
}
</script>

<style scoped>
.img-card {
    width: 280px;
    border: 1px solid #fff;
    cursor: pointer;
}

.modal {
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
}

.modal-body {
    margin: auto;
    background-color: #fff;
    max-width: 600px;
    width: auto;
    height: auto;
    position: relative;
    display: flex;
    justify-content: center;
}

.img-card-modal {
    width: 50%;
}

.close {
    width: 24px;
    height: 24px;
    background-color: #fff;
    border: 1px solid transparent;
    border-radius: 24px;
    position: absolute;
    right: -13px;
    top: -13px;
    size: 24px;
    color: tomato;
    font-weight: 800;
    text-align: center;
    cursor: pointer;
}
</style>