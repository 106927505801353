<template>
    <div class="container-fluid py-4 px-5">
        <h3>Galeria de Imagem
            <img class="icon-badeira" src="../assets/img/icons/icon-alert.png" alt="logo-galeria" srcset="">
        </h3>
        <section class="mt-5">
            <Tables />
        </section>
    </div>
</template>
<script>
import Tables from '../components/Tables.vue'
export default {
    name: 'Home',
    components: { Tables }
}
</script>